<template>
	<div>
		
		
		<div id="header">
			
			<div class="header-mobile mobile">
				<img :src="project.logo" />
				<div id="connect_wallet">
					<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
					<div v-else>
						{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
						Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
					</div>
				</div>
			</div>
			<div style="width: 320px; text-align: left; padding: 17px;"><img :src="project.logo" class="desktop" /></div>
			<div id="connect_wallet"  class="desktop">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
					Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
				</div>
			</div>
			<div class="advertisement">
				<a href="https://effe2.xyz/" target="_blank" style="color: #fff; text-decoration: none;"> 
					Powered by Effe²<br/>
					<span>Free tools for NFT projects on Solana</span>
				</a>
			</div>
		</div>
		
		<div id="raffles">
			<div class="raffle-container">
				<template>
					<div class="raffle-background-container">
						
						<h2 style="text-align: center;">Send DM as NFT for free !</h2>
					</div>
					
					<div class="raffle-background-container">
						<div class="dm-text-line" v-for="(line, index) in lines" :key="index">
							<span>Line {{ index+1 }}:</span>
							<input type="text" v-model="line.text" maxlength="50" />
						</div>
					</div>
					<div class="raffle-background-container" style="text-align: center;">
						<div class="raffle-button button-primary" @click="preview_message()">Preview</div><br/><br/>
						
						<img :src="'https://back.the-cocktail-crew.com/storage/dm/'+src_preview+'.png'" v-if="src_preview !== null" />
					</div>
					<div class="raffle-background-container" style="text-align: center;" v-if="src_preview !== null">
						<div class="dm-text-line">
							<span>To wallet:</span>
							<input type="text" v-model="to_wallet" id="dm-to-wallet" />
						</div>
						<div class="raffle-button button-primary" @click="send_message()">Send</div>
					</div>
					
				</template>
			</div>
		</div>
		
	</div>
</template>


<script>


import { mint_and_send_nft } from '@/libs/rustProgram';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {getProject} from '@/libs/project';
import $ from 'jquery';
import { establishConnection, getTokenAccountForTokenAndOwner, getTokenAccountBalance, confirmTransaction, getNftOwned, getNftMetadata } from "@/libs/solanaConnection";
import { PublicKey } from '@solana/web3.js';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

var connection = null;


export default {
	name: 'Dm',
	components: {},

	data: function () {

		return {
			
			token_balance: 0,
			project: {},
			to_wallet: '',
			lines: [
				{text: ''},
				{text: ''},
				{text: ''},
				{text: ''},
				{text: ''},
				{text: ''},
				{text: ''},
			],
			src_preview: null,
		}
	},
	
	computed: {
		
		
		
	},

	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
		
		});
		
		this.$root.$on('walletConnected', function() {
			
			$this.get_token_balance();
			
		});
		
		this.$root.get_project_ready = true;
		
		// this.$root.$emit('openPopup');
	},
	mounted: function(){
	
	},

	methods: {
	
		preview_message: async function() {
		
			var lines = this.lines;
			
			this.$root.$emit('openLoader');
		
			var image = await axios.post('https://back.the-cocktail-crew.com/dm/create-nft', {lines: lines});
			
			this.src_preview = image.data.src;
			
			this.$root.$emit('closeLoader');
		},
		
		send_message: async function() {
		
			if(this.$root.wallet_address === null) {
				
				this.$root.$emit('openPopup');
				return;
			}
			
			var signature, confirmed_transaction;
			
			var project = this.project;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signature = await mint_and_send_nft(getSolanaObject(), getWalletAddress(), this.src_preview, this.to_wallet);
				
				confirmed_transaction = await confirmTransaction(signature);
				
				if(confirmed_transaction === true) {
				
					this.$root.modal = {title: 'Perfect !', text: "Message sent successfully !"};
					this.$root.$emit('openModal');
				}
				else {
					
					this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
					this.$root.$emit('openModal');
				}
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
		},
	
		
		get_token_balance: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			var buyer_token_account = await getTokenAccountForTokenAndOwner(this.project.token_address, getWalletAddress());
			
			var balance = await getTokenAccountBalance(new PublicKey(buyer_token_account));
			
			this.token_balance = balance;
		},
		
		
	
		
		
	},
	watch:{}
	}
</script>



